import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IWizardState } from "@interfaces/store/IWizardState"

const initialState: IWizardState = {
  loading: false,
  step: 0,
  business: [],
  departments: [],
  labels: [],
  roles: [],
  types: [],
  errors: {},
}

const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    setStopLoading: (state) => {
      state.loading = false
    },
    stepForward: (state) => {
      state.step = state.step + 1
    },
    stepReset: (state) => {
      state.step = 0
    },
    stepBack: (state) => {
      state.step = state.step - 1
    },
    setLabels: (state, action: PayloadAction<any>) => {
      state.labels = action.payload
      state.loading = false
    },
    setDepartments: (state, action: PayloadAction<any>) => {
      state.departments = action.payload
      state.loading = false
    },
    setType: (state, action: PayloadAction<any>) => {
      state.types = action.payload
      state.loading = false
    },
    setBusiness: (state, action: PayloadAction<any>) => {
      state.business = action.payload
      state.loading = false
    },
    setErrors: (state, action: PayloadAction<any>) => {
      state.errors = action.payload
      state.loading = false
    },
    clearErrors: (state) => {
      state.errors = []
      state.loading = false
    },
  },
})

export default wizardSlice
