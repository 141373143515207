import { ICredentialModel } from "@interfaces/models/security/ICredentialModel"
import { HttpService } from "@services/core/http.service"
import { IHttpRequestParams } from "@interfaces/services/IHttpRequestParams"
import { LoggerService } from "@services/core/logger.service"

export class AuthService {
  private static _instance: AuthService | null = null

  private constructor() {}

  public static instance(): AuthService {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService()
    }
    return AuthService._instance
  }

  public async login(data: ICredentialModel) {
    try {
      const params: IHttpRequestParams<ICredentialModel> = {
        url: "login",
        secured: false,
        payload: data,
      }
      return await HttpService.instance().post<any>(params)
    } catch (error) {
      LoggerService.instance().error({ message: error as string })
      throw error
    }
  }

  public async forgotPassword(email: String) {
    try {
      const params: IHttpRequestParams<any> = {
        url: "password/reset",
        secured: false,
        payload: { email },
      }
      return await HttpService.instance().post<any>(params)
    } catch (error) {
      LoggerService.instance().error({ message: error as string })
      throw error
    }
  }
}
