// Packages
import React from "react"
import { Navigate } from "react-router-dom"

// Utils
import { ICustomRouteProps } from "@interfaces/components/ICustomRouteProps"
import { StorageService } from "@services/core/storage.service"
import { parseJwt } from "@utils/helpers"
import { logoutAction } from "@store/security/security.actions"
import { useAppDispatch } from "@hooks/useAppDispatch"

const ProtectedRoute: React.FC<ICustomRouteProps> = (props) => {
  const dispatch = useAppDispatch()
  const isAuthenticated = StorageService.instance().item("security_context")
  if (isAuthenticated === null) return <Navigate to="/login" replace />
  const dataUser = JSON.parse(isAuthenticated)
  const tokenData = parseJwt(dataUser.access_token)
  if (tokenData && tokenData.exp && tokenData.exp < Date.now() / 1000) {
    dispatch(logoutAction())
    return <Navigate to="/login" replace />
  }
  return props.children
}

export default ProtectedRoute
