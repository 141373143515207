import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "@store"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { XIcon } from "@heroicons/react/outline"
import CustomRouter from "@utils/CustomRouter"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const close = <XIcon className="h-5 w-5 m-2 self-center" />
root.render(
  <React.StrictMode>
    <CustomRouter>
      <Provider store={store}>
        <App />
        <ToastContainer hideProgressBar={true} closeButton={close} />
      </Provider>
    </CustomRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
