import { ILoggerService } from "@interfaces/services/ILoggerService"
import { ILogPayload } from "@interfaces/services/ILogPayload"

export class LoggerService implements ILoggerService {
  private static _instance: LoggerService | null = null

  private constructor() {}

  public static instance(): LoggerService {
    if (!LoggerService._instance) {
      LoggerService._instance = new LoggerService()
    }
    return LoggerService._instance
  }

  debug(payload: ILogPayload): void {
    LoggerService.log("debug", payload)
  }

  error(payload: ILogPayload): void {
    LoggerService.log("error", payload)
  }

  info(payload: ILogPayload): void {
    LoggerService.log("info", payload)
  }

  warning(payload: ILogPayload): void {
    LoggerService.log("warn", payload)
  }

  private static log(type: "debug" | "info" | "warn" | "error", payload: ILogPayload): void {
    if (payload.supportingDetails && payload.supportingDetails?.length > 0)
      console[type](payload.message, payload.supportingDetails)
    else console[type](payload.message)
  }
}
