import { AnyAction, nanoid, ThunkAction } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { INotification } from "@interfaces/store/INotification"

export const notifyAction = (
  notification: INotification,
): ThunkAction<any, any, any, AnyAction> => {
  return () => {
    switch (notification.type) {
      case "info":
        toast.info(notification.message, {
          toastId: nanoid(),
        })
        break
      case "success":
        toast.success(notification.message, {
          toastId: nanoid(),
        })
        break
      case "warning":
        toast.warning(notification.message, {
          toastId: nanoid(),
        })
        break
      case "error":
        toast.error(notification.message, {
          toastId: nanoid(),
        })
        break
    }
  }
}
