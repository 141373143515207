import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IBusinessState } from "@interfaces/store/IBusinessState"

const initialState: IBusinessState = {
  data: {
    attributes: [],
    events: {},
    business: [],
    nomenclatures: {},
    paginate: {
      currentPage: 1,
      total: 1,
      perPage: 10,
      path: "",
      firstPageUrl: "",
      lastPageUrl: "",
      nextPageUrl: "",
      prevPageUrl: "",
    },
    businessShareData: [],
  },

  loading: false,
  loadingWebhook: false,
  loadingNomenclatures: false,
  loadingBusinessShareData: false,
  error: false,
  errorWebHooks: {},
}

const businessSlice = createSlice({
  name: "Business",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    setComplete: (state) => {
      state.loading = false
    },
    setLoadingNomenclatures: (state) => {
      state.loadingNomenclatures = true
    },
    setCompleteNomenclatures: (state, { payload }: PayloadAction<any>) => {
      state.loadingNomenclatures = false
      state.data.nomenclatures = payload
    },

    setLoadingBusinessShareData: (state) => {
      state.loadingBusinessShareData = true
    },
    setCompleteBusinessShareData: (state, { payload }: PayloadAction<any>) => {
      state.loadingBusinessShareData = false
      state.data.businessShareData = payload
    },

    setError: (state) => {
      state.loading = false
      state.error = true
    },
    setFetchBusinessComplete: (state, { payload }: PayloadAction<any>) => {
      const {
        data: business,
        current_page: currentPage,
        total,
        path,
        per_page: perPage,
        first_page_url: firstPageUrl,
        last_page_url: lastPageUrl,
        next_page_url: nextPageUrl,
        prev_page_url: prevPageUrl,
      } = payload

      state.data.business = business
      state.data.paginate = {
        currentPage: parseInt(currentPage),
        total: parseInt(total),
        perPage: parseInt(perPage),
        path,
        firstPageUrl,
        lastPageUrl,
        nextPageUrl,
        prevPageUrl,
      }
      state.loading = false
      state.error = false
    },
    setFetchBusinessCompletePerUser: (state, { payload }: PayloadAction<any>) => {
      state.data.business = payload
      state.loading = false
      state.error = false
    },

    setEvents: (state, { payload }: PayloadAction<any>) => {
      state.data.events = payload
      state.loading = false
      state.error = false
    },
    setAttributes: (state, { payload }: PayloadAction<any>) => {
      state.data.attributes = payload
      state.loading = false
      state.error = false
    },
    setLoadingWebhook(state) {
      state.loadingWebhook = true
    },
    setCompleteWebhook(state) {
      state.loadingWebhook = false
    },
    setErrorWebhook(state, { payload }: PayloadAction<any>) {
      state.errorWebHooks = payload
      state.loadingWebhook = false
    },
    resetErrorWebhook(state) {
      state.errorWebHooks = {}
    },
  },
})

export default businessSlice
