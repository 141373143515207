import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { StorageService } from "@services/core/storage.service"
import { IHttpClient } from "@interfaces/services/IHttpClient"
import { IHttpRequestParams } from "@interfaces/services/IHttpRequestParams"
import { HEADER_ACCEPT, HEADER_CONTENT_TYPE } from "@utils/constants"
import store from "@store"
import { logoutAction } from "@store/security/security.actions"

import * as CONSTANTS from "@utils/constants"
import { rootNavigate } from "@utils/CustomRouter"

const headers: Readonly<Record<string, string | boolean>> = {}

const injectToken = (secured: boolean): AxiosRequestConfig => {
  const config: AxiosRequestConfig = {
    headers: headers,
  }
  const securityContext = StorageService.instance().item("security_context")
  if (securityContext !== null && config.headers !== undefined && secured) {
    const { access_token: accessToken } = JSON.parse(securityContext)
    config.headers.Authorization = `Bearer ${accessToken}`
    config.headers["Accept"] = HEADER_ACCEPT
    config.headers["Content-Type"] = HEADER_CONTENT_TYPE
  }
  return config
}

const saveToken = (token: string) => {
  const securityContext = StorageService.instance().item("security_context")
  if (securityContext !== null) {
    const security = { ...JSON.parse(securityContext), access_token: token }
    StorageService.instance().save({ key: "security_context", value: JSON.stringify(security) })
  }
}

const logout = () => {
  store.dispatch(logoutAction())
  rootNavigate(CONSTANTS.ROUTES.LOGIN)
}

export class HttpService implements IHttpClient {
  private static _instance: HttpService
  private axiosInstance: AxiosInstance | null = null

  private constructor() {}

  public static instance(): HttpService {
    if (!HttpService._instance) {
      HttpService._instance = new HttpService()
    }
    return HttpService._instance
  }

  private get http(): AxiosInstance {
    return this.axiosInstance !== null ? this.axiosInstance : this.initializeHttp()
  }

  private initializeHttp(): AxiosInstance {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: headers,
    })
    this.axiosInstance = http
    this.initializeInterceptors()
    return http
  }

  private initializeInterceptors(): void {
    this.axiosInstance?.interceptors.response.use(
      HttpService.handleResponse,
      HttpService.handleError,
    )
  }

  private static handleResponse(response: AxiosResponse) {
    const { regenerate_token } = response.headers
    if (regenerate_token) {
      saveToken(regenerate_token)
    }
    return response
  }

  private static handleError(error: any) {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400 && data.message === "Unauthenticated.") {
        logout()
      } else if (status === 401) {
        logout()
      }
    }
    throw error
  }

  get<T>(params: IHttpRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, secured } = params
      const config = injectToken(secured)
      this.http
        .get(url, config)
        .then((response: any) => {
          resolve(response as T)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  post<T>(params: IHttpRequestParams<T>): Promise<T> {
    return new Promise(async (resolve, reject) => {
      const { url, secured, payload } = params
      const config = injectToken(secured)

      this.http
        .post(url, payload, config)
        .then((response: any) => {
          resolve(response as T)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  put<T>(params: IHttpRequestParams<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const { url, secured, payload } = params
      const config = injectToken(secured)
      return this.http
        .put(url, payload, config)
        .then((response: any) => {
          resolve(response as T)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }

  delete<T>(params: IHttpRequestParams<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      const { url, secured } = params
      const config = injectToken(secured)
      return this.http
        .delete(url, config)
        .then((response: any) => {
          resolve(response as T)
        })
        .catch((error: any) => {
          reject(error)
        })
    })
  }
}
