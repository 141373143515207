export const inputRegexMatch = (value: string) => {
  return /^\d*\.?\d*$/.test(value)
}

export const haveMorePages = (paginate: any) => {
  return Math.floor(paginate.total / paginate.perPage + 1) > 1
}

export const transform = (arr: any[]) => {
  return (
    arr?.map((e) => {
      return { ...e, label: e.name, value: e.id }
    }) ?? []
  )
}

export const processError = (error: any, join = true) => {
  const errors = error.response?.data?.data
  if (!errors) {
    return "An error occurred. Please try again."
  }
  if (typeof errors === "string") {
    return errors
  }
  const keys = Object.keys(errors)
  const plainErrors: any = {}
  keys.forEach((key) => {
    plainErrors[key] = Array.isArray(errors[key]) ? errors[key].join("") : errors[key].toString()
  })
  return join ? Object.values(plainErrors).join("") : plainErrors
}

export const parseJwt = (token: string) => {
  if (!token) return undefined
  let base64Url = token.split(".")[1]
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )

  return JSON.parse(jsonPayload)
}

export const deepSearch = (object: any, prop: string, value: string) => {
  let array = []
  if (Array.isArray(object)) {
    array = [...object]
  } else {
    array.push(object)
  }
  while (array.length > 0) {
    const o = array.pop()
    if (o.hasOwnProperty(prop) && o[prop] === value) {
      return o
    }
    if (o.hasOwnProperty("children")) {
      const children = o.children
      array.push(...children)
    }
  }
  return undefined
}
