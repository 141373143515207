import { AnyAction, ThunkAction } from "@reduxjs/toolkit"
import { AuthService } from "@services/auth.service"
import { ICredentialModel } from "@interfaces/models/security/ICredentialModel"
import securitySlice from "./security.slice"
import { StorageService } from "@services/core/storage.service"
import { IStorageItem } from "@interfaces/services/IStorageItem"
import { notifyAction } from "@store/notifications/notifications.actions"

export const securityActions = securitySlice.actions

export const loginAction = (
  data: ICredentialModel,
): ThunkAction<void, unknown, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(securityActions.setLoginLoading())
      const response: any = await AuthService.instance().login(data)
      if (response) {
        const { user, permissions, privileges, is_guest } = response.data.data
        dispatch(securityActions.setLoginComplete({ ...user, permissions, privileges, is_guest }))
        const securityContext: IStorageItem = {
          key: "security_context",
          value: JSON.stringify({ ...response.data.data }),
        }
        StorageService.instance().save(securityContext)
      }
    } catch (error) {
      dispatch(securityActions.setLoginFailed())
      throw error
    }
  }
}

export const logoutAction = (): ThunkAction<void, unknown, unknown, AnyAction> => {
  return (dispatch) => {
    StorageService.instance().remove("security_context")
    dispatch(securityActions.setLogoutComplete())
  }
}

export const forgotPasswordAction = (
  email: String,
): ThunkAction<void, unknown, unknown, AnyAction> => {
  return async (dispatch) => {
    try {
      dispatch(securityActions.setForgotPasswordLoading())
      const { data } = await AuthService.instance().forgotPassword(email)
      if (!data.success) {
        dispatch(securityActions.setForgotPasswordError(data.data))
        return
      }
      dispatch(securityActions.setForgotPasswordCompleted())
      dispatch(
        notifyAction({
          type: "success",
          message: data.data,
        }),
      )
    } catch (error) {
      dispatch(securityActions.setForgotPasswordError({ email: error as string }))
    }
  }
}
